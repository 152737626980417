const variable = {
	source: "reviews",

	staticBaseUrl: "",
	//==========:嵌入星星相关================
	//内容宽度
	wrapWid: 0,

	// 是否是无评论区
	isNullRev: false,

	//嵌入星星目标
	proTarget: null,
	collTarget: [],
	ratingIconSvg: "",
	badgeIconSvg: "",

	//星星排列方向
	themeStyle: "",
	// 客户端ip
	ip: "",
	// 是否首次运行（没有缓存）
	isFirstExecute: false,

	//各页面判断
	isCollPage: false,
	isProPage: false,
	isHomePage: false,
	// 是否为购物车页
	isCartPage: false,

	//数据更新判断，更新进行接口请求
	isSettingUpdate: false,
	isProRatingUpdate: false,
	isReviewsUpdate: false,

	// 浏览器是否支持webp
	isWebPSupported: true,

	// 评论区类名头缀
	revIdBase: "vstar-",

	isOverwriteOldScripts: false,

	// 是否已记录pv
	isRecordPV: false,
	// 是否已加购物车行为
	isRecordCart: false,

	// 购物车监听器
	cartWatcher: null,

	RevIntersection: null,

	//==========评论列表相关 product==========//

	//页面星星是否展示
	isCollOpen: false,
	isProOpen: false,
	isRevOpen: false,
	// 是否第一次渲染评论区的第一页评论
	isFirstPageRender: true,

	// 买家秀展示类型
	buyersShowType: 2,
	// 是否存在买家秀列表
	isInsertBS: false,
	// 混合评论区
	isMixReview: false,
	// 第几页评论数据
	page: 0,
	// 弹窗评论区评论第一页是否已渲染
	isWdoRevFirstPageRendered: false,
	// 每页评论数据
	reviewsList: [],
	//评论页数标记
	pageRecord: [],
	// 评论区评论列表的dom元素
	reviewsBody: null,
	// 评论区区的dom元素
	reviewsDom: null,

	// 评论列表请求参数（用于更新缓存）
	revReqParam: "",

	productHandle: "",
	//是否为移动端

	//用户配置
	/**
	 * @type {Settings}
	 */
	userSetting: {},
	// 展示的什么类型，1为评论/2为QA
	showType: 1,

	//============评论区布局相关============//

	//评论布局
	layoutClass: "",
	//评论区布局每次请求的评论数
	layoutReviewCount: 0,
	//窗口大小
	reviewsWidth: 0,
	//评论开始省略的行数
	reviewsRow: 5,
	//已上传图片数量
	imgUploadedCount: 0,
	//上传图片标识
	uploadedIndex: 0,
	//grid布局卡片宽度
	gridCardWidth: 0,
	// 弹窗评论区grid布局卡片宽度
	wdoGridCardWidth: 0,

	// list评论区评论节点显示索引
	listDisIndex: 0,
	// list评论区评论节点数量
	listNodeCount: 0,

	//评论列表图片预览器
	viewer: null,

	//预加载图片数组
	origImg: [],
	// list缩略图片数组
	thumbImg: [],

	// 赞踩评论提交时锁定
	likeRevLock: false,

	//多语言
	lang: {},

	//瀑布流相关
	column: 0,
	minHeight: 0,
	// 瀑布流每行高度、每行前高度、卡片宽度、卡片数量
	//评分筛选 0为全部，1-5为对应分值的评论
	reviews: {
		columnHighs: [],
		preColHighs: [],
		cardWid: 0,
		cardCount: 0,
		ratingFilter: 0,
	},

	// 买家秀嵌入选择器
	bsInsertSelector: null,
	albumCount: 8,
	albumSortType: "commented-at-descending",
	// 是否确定会嵌入评论区
	isInsertRev: false,

	// 网络css是否已加载
	isCssLoaded: false,
	// 内联css
	globalStyle: null,
	//请求错误信息
	reqErr: null,

	//Mark 全局变量声明

	// ============产品页=============
	isProHasHalfStar: false,
	pageCount: 0,
	//产品总评
	ratingData: {},
	//全产品总评
	allRatingData: {},

	//=======产品列表相关 collection======

	// 从集合页产品页列表，目标元素向上找a标签次数
	searchACount: 6,
	// 当前嵌入目前索引
	collTarInx: 0,
	// 是否是shopify官方以外的主题
	isOtherTheme: false,
	// 是否完成嵌入
	isCollFinished: false,
	//监听排序
	observer: null,
	// 没有星级评价时设置监听（例如懒加载的列表）
	isSetObserver: false,

	// 显示监听 用于滚动加载监听
	iObserver: null,

	// 当前请求产品的集合的数据数组
	curCollArr: [],

	// ================home页==========
	// 首页星星嵌入是否开启
	isHomeRatingOpen: false,

	// 停止全部评论区的滚动加载
	allRevLoadLock: false,

	//============写评论弹框相关============
	mediaUrl: [],
	// 写评论间隔锁
	isSendLock: false,
	// 写评论弹窗设定评分默认值
	ratingVal: 0,
	//上传图片需要的相关信息
	uploadImgInfo: {},
	// 上传文件类型 1：图片 2：视频
	uploadType: 1,

	// 评论内容限制的字数
	newRevCharLimit: 2000,
	isLoginWrite: false,
	copyText: "Copy successfully",

	//============弹窗评论区相关============
	// 瀑布流每行高度、每行前高度、卡片宽度、卡片数量
	wdoRev: {
		columnHighs: [],
		preColHighs: [],
		cardWid: 0,
		cardCount: 0,
		ratingFilter: 0,
	},
	//是否开启弹窗评论区
	isActWdoRev: false,
	// 弹窗评论区是否显示
	isWdoRevOpen: false,
	// 弹窗评论区是否已嵌入
	isWdoRevExist: false,
	// 弹窗评论区的dom元素
	wdoRevDom: false,
	wdoRevBody: null,
	isLinkReqRev: false,
	writeForm: 0,
	writeRestriction: 1,

	//pagefly集成

	//是否存在py星星控件
	hasPyRating: false,

	// 产品页评论区产品组是否显示关联产品
	showRelatedPros: false,

	//===========评论详情弹窗相关===========
	revDetailInfo: {
		page: 0, // 评论属于第几页评论
		no: 0, // 评论卡片的序号
		imageIndex: 0, // 当前显示图片的索引
	},

	// QA相关
	isQAInserted: false,
	qsPageNum: 0,
};

/**
 * @typedef {Object} Settings
 *
 * @property {number} is_install - 1
 * @property {number} is_activate_reviews - 1
 * @property {number} theme_style - 1
 * @property {number} layout - 1
 * @property {string} font_color - "#243537"
 * @property {string} star_color - "#834242"
 * @property {string} card_bg_color - "#ce6767"
 * @property {string} box_bg_color - "#ce9d9d"
 * @property {number} is_activate_product_rating - 1
 * @property {number} is_show_country_flag - 1
 * @property {number} is_transparent - 2
 * @property {number} is_show_google_snippet - 1
 * @property {string} language - "ja"
 * @property {string} lang_reviews - "评论"
 * @property {number} is_activate_collection_rating - 1
 * @property {number} is_activate_home_rating - 2
 * @property {number} is_show_list_stars_without_review - 1
 * @property {number} is_show_stars_without_review - 1
 * @property {number} is_translate_review - 2
 * @property {number} is_activate_home_reviews - 2
 * @property {string} theme_version - ""
 * @property {number} is_del_powered - 1
 * @property {number} reviews_per_page_number - 14
 * @property {string} review_sort_by - "rating-descending"
 * @property {number} is_hide_ratings - 2
 * @property {string} verified_badge_color - "#6969cb"
 * @property {string} all_reviews_page_handle - "reviews"
 * @property {sidetabSettings} sidetab_setting -
 * @property {number} review_pagination_type - 1
 * @property {number} auto_switch_language - 2
 * @property {number} is_show_review_button - 1
 * @property {string} badge_text_color - "#4a4ac2"
 * @property {number} is_show_verified_badge - 1
 * @property {number} review_date_format_type - 1
 * @property {number} rating_filter - 1
 * @property {string} button_bg_color - "#7683be"
 * @property {string} button_color - "#339fd0"
 * @property {string} button_border_color - "#191925"
 * @property {number} font_size - 16
 * @property {string} nostar_color - "#4e4e9a"
 * @property {string} dividing_line_color - "#7d9527"
 * @property {string} card_shadow - "Light"
 * @property {number} border_radius - 12
 * @property {number} is_show_helpful - 2
 * @property {number} collection_pages_display_content - 1
 * @property {number} review_empty_status_display - 2
 * @property {string} reply_bg_color - "#556985"
 * @property {number} is_qa_active - 1
 * @property {string} qa_colors - "#344531,#efaa39,#19281b,#cf2c2c,#2a4b27,#8a5796,"
 * @property {number} item_type - 2
 * @property {number} is_show_album - 1
 * @property {number} rating_icon_type - 2
 * @property {number} verified_icon_type - 3
 * @property {number} customer_avatar - 1
 * @property {string} store_avatar_src - "https://img.trustoo.io/test/61636051159/2024/9/3/Bt4kjeazRf.png"
 * @property {string} store_name - "NJXJ018 TEST"
 * @property {string} avatar_bg_color - "#3b1d1d"
 * @property {string} avatar_text_color - "#c1a4a4"
 * @property {carouselSettings} card_carousel_setting"
 * @property {number} album_radius - 8
 * @property {number} is_show_review_number - 1
 * @property {number} photo_radius - 3
 */

/**
 * @typedef {Object} sidetabSettings
 * @property {string} sidetab_background_color - The person's name.
 * @property {string} sidetab_button_text - The person's age.
 * @property {string} sidetab_font_color - Whether the person is employed.
 * @property {number} sidetab_is_enabled - Whether the person is employed.
 */
/**
 * @typedef {Object} carouselSettings
 * @property {number} is_home_active - The person's name.
 * @property {number} is_product_active - The person's age.
 */

export default variable;

// module.exports = variable
