import { requests } from "../common/network";
import { addReviewWindow, successSend } from "../reviews/allinone-write-review";
import {
	getEmptyReview,
	getReviews,
	setReviewShowObserver,
} from "../reviews/reviews";
import { $dqs, $dqsa, gsSession, scrollTo } from "../common/utils";
import {
	getCollectionTarget,
	setCollectionObserve,
	getProductsANodes,
	getProductHandleStrings,
} from "./collection";
import { getProductTarget, proClickTabActive } from "./product";

import v from "../common/variable";
import u from "../user_need/user_need";
import { insertSlide } from "../reviews/slide-write-review";
import { CartWatcher } from "./obseverCart";

//Mark 产品、集合、主页页面获取产品评价
async function setInsertTarget(type) {
	let productHandle, productsId;
	if (type == 1) {
		if (!$dqs("#seal-star-rating-widget")) {
			await getProductTarget();
			if (!Review.proTarget) {
				return;
			}
			v.globalStyle.textContent += `
			.product-icon-list>.tt-rating-text{
          color: ${window.getComputedStyle(Review.proTarget).color} !important;
        }`;
			proInsertStar(undefined);
		}
	} else if (type == 2) {
		const collTarLen = Review.collTarget.length;
		if (collTarLen === 0) {
			return;
		}
		v.curCollArr = Review.collTarget.filter(i => {
			const isInserted = i.getAttribute("is-vstar-inserted");
			if (!isInserted) {
				return true;
			}
		});
		if (v.curCollArr.length > 50) {
			v.isCollFinished = false;
			v.curCollArr = v.curCollArr.slice(0, 50);
		} else v.isCollFinished = true;

		const isUseId = v.curCollArr.every(
			it => it.getAttribute("product-id") || it.dataset.productId
		);
		if (isUseId) {
			productsId = v.curCollArr
				.map(
					i => i.getAttribute("product-id") || i.getAttribute("data-product-id")
				)
				.join(",");
		} else {
			let nodeList = getProductsANodes();
			productHandle = getProductHandleStrings(nodeList);
		}
	}
	if (type !== 1) {
		if (productHandle || productsId) {
			let [reqErr, collProData] = await requests.getCollRating(
				productHandle,
				productsId
			);
			reqErr === null && collInsertStar(collProData);
		}
	}
}

//Mark 集合页嵌入星星
async function collInsertStar(resData) {
	const curCollArr = v.curCollArr;
	const { userSetting, isProPage, hasPyRating, hasEcomRt } = v;

	const collLen = curCollArr.length;
	if (collLen) {
		if ($dqsa(".collection-icon-list").length === 0) {
			const titleColor = getComputedStyle(curCollArr[0]).color;
			v.globalStyle.textContent += `
			.collection-icon-list>.tt-rating-text{
				color:${titleColor}
			}
			`;
		}
		let index = 0;
		let position = "afterEnd";
		if (hasPyRating || hasEcomRt) position = "afterbegin";

		if (v.shop_id === 55363502159) {
			resData.forEach(i => (i.total_reviews += 100));
		}
		for (var i = 0; i < collLen; i++) {
			let data = resData[index];
			index++;
			let align = "";

			const target = curCollArr[i];
			target.setAttribute("is-vstar-inserted", true);

			const tarSty = window.getComputedStyle(target);
			let tarAlign = tarSty.textAlign;
			const direction = tarSty.direction;
			if (tarAlign === "center") align = "center";
			else if (tarAlign === "right" && direction !== "rtl") align = "right";
			else target.classList.remove("center");
			const p = target.parentNode;

			if (
				!target ||
				data.rating === -1 ||
				$dqs(".collection-icon-list,.seal-stars", p) ||
				(v.isProPage && $dqs("#seal-star-rating-widget", p))
			) {
				continue;
			}
			const rating =
				data.rating === "0"
					? data.rating
					: parseFloat(data.rating).toFixed(u.bigRatingDigits - 1);
			if (userSetting.is_show_list_stars_without_review === 2 && rating == 0) {
			} else {
				let preStarHtml = "",
					nextStarHtml = "",
					starHtml = "";

				if (u.isCollRevWord) {
					starHtml = getTotalStarString(data.rating, true);
					nextStarHtml = data.total_reviews + " " + v.lang.reviews;
				} else {
					const number =
						userSetting.is_show_review_number === 1 ? data.total_reviews : "";
					let content = v.lang.list_rating_content
						.replace(/{{review_number}}/g, number)
						.replace(/{{review_rating}}/g, rating)
						.trim();
					const starInx = content.indexOf("{{star}}");
					if (starInx !== -1) {
						if (starInx !== 0) {
							preStarHtml = `<div class="tt-rating-text" style="margin-left: 0px;">${content.slice(
								0,
								starInx
							)}</div>`;
						}
						nextStarHtml = `<div class="tt-rating-text collection-reviews-num">${content.slice(
							starInx + 8
						)}</div>`;
						starHtml = getTotalStarString(data.rating, true);
					} else {
						nextStarHtml = `<div class="tt-rating-text">${content}</div>`;
					}
				}

				target.style.margin = 0;
				const icon_list_string = `<div class="collection-icon-list vstar-star ${align}" data-review-num="${data.total_reviews}" rating="${rating}">
				${preStarHtml}${starHtml}${nextStarHtml}</div>`;
				target.insertAdjacentHTML(position, icon_list_string);
			}
		}

		document.dispatchEvent(
			new CustomEvent("onTTListRatingEmbed", {
				detail: {
					v,
				},
				bubbles: true,
				cancelable: true,
			})
		);

		if ((hasPyRating || hasEcomRt) && isProPage) {
			const productId = shopifyObj.productId;
			const tarRating = $dqsa(
				`.custom-vstar-rating-widget[product-id="${productId}"],.custom-vstar-rating-widget[data-product-id="${productId}"]`
			);
			if (tarRating.length !== 0 && v.reviewsDom) {
				tarRating.forEach(i => {
					i.style.cursor = "pointer";
					i.onclick = () => scrollTo(v.reviewsDom);
				});
			}
		}

		if (!isProPage || hasPyRating) {
			if (!v.isCollFinished) {
				setInsertTarget(2);
			} else {
				setTimeout(() => {
					setCollectionObserve();
				}, 1000);
			}
		}
	}
}

/**
 * ## 产品页嵌入星星
 */
function proInsertStar() {
	let { proTarget } = Review;
	const { ratingData, userSetting, lang, shop_id } = v;

	const existingNode = $dqs(
		".product-icon-list.vstar-star",
		proTarget.parentNode
	);
	existingNode && existingNode.remove();

	if (
		userSetting.is_show_stars_without_review === 2 &&
		ratingData.rating == 0
	) {
	} else {
		let position = "afterEnd";
		let exCls = "",
			themeStyle = "";
		let tarAlign = window.getComputedStyle(proTarget).textAlign;

		if (shop_id === 55363502159) {
			ratingData.total_reviews += 100;
		}
		if (proTarget.dataset.trustooRatingBlock == 1) {
			position = "afterbegin";
		}
		const parentStyle = window.getComputedStyle(proTarget.parentNode);
		if (
			parentStyle.display === "flex" &&
			parentStyle.flexDirection !== "column" &&
			parentStyle.flexWrap !== "wrap"
		) {
			proTarget = proTarget.parentNode;
		}

		if (tarAlign === "center") exCls = "center";
		else if (tarAlign === "right") exCls = "right";
		if (!v.isCssLoaded) themeStyle += "display:none;";
		else if (v.isProHasHalfStar) {
			themeStyle += "display:none;";
			setTimeout(() => {
				$dqs(".product-icon-list.vstar-star").style.display = "block";
			}, 300);
		}

		if (u.customRatingText) {
			lang.product_pages_reviews = u.customRatingText;
		}
		const number =
			userSetting.is_show_review_number === 1 ? ratingData.total_reviews : "";
		let content = lang.product_pages_reviews
			.replace(/{{review_number}}/g, number)
			.replace(/{{review_rating}}/g, ratingData.rating)
			.trim();
		const starInx = content.indexOf("{{star}}");
		let preStarHtml = "",
			nextStarHtml = "",
			starHtml = "";
		if (starInx !== -1) {
			if (starInx !== 0) {
				preStarHtml = `<div class="tt-rating-text" style="margin-left: 0px;">${content.slice(
					0,
					starInx
				)}</div>`;
			}
			nextStarHtml = `<div class="tt-rating-text">${content.slice(
				starInx + 8
			)}</div>`;
			starHtml = getTotalStarString(ratingData.rating, true);
		} else {
			nextStarHtml = `<div class="tt-rating-text">${content}</div>`;
		}

		const icon_list_string = `<div class="product-icon-list vstar-star ${exCls}" data-product-id="${v.productId}" style="${themeStyle}">
			${preStarHtml}${starHtml}${nextStarHtml}</div>`;

		proTarget.insertAdjacentHTML(position, icon_list_string);
		proTarget.style.marginBottom = 0;
		let reviewNum = $dqs(".product-icon-list");
		reviewNum.onclick = function (e) {
			const { reviewsDom } = v;

			processRecordedData("reviews_click");

			if (!e.target.classList.contains("product-icon-list")) {
				proClickTabActive();
				reviewsDom && scrollTo(reviewsDom);
			}
		};
		const onTTDetailRatingEmbed = new CustomEvent("onTTDetailRatingEmbed", {
			detail: {
				v,
			},
			bubbles: true,
			cancelable: true,
		});
		document.dispatchEvent(onTTDetailRatingEmbed);
	}
}

//Mark  控件嵌入控制
async function getTotalRating(windowPro = {}) {
	let reqErr, ratingData;
	const {
		isProRatingOpen,
		isHomeRatingOpen,
		isMixReview,
		isWdoRevOpen,
		isHomePage,
		isProPage,
	} = v;
	const { proTarget } = Review;
	const shopifyProBlock = $dqs("#seal-star-rating-widget");

	if ((!isMixReview && !isWdoRevOpen) || v.productId) {
		if (
			typeof vstar_product_rating !== "undefined" &&
			vstar_product_rating.hasOwnProperty("rating") &&
			!v.isProRatingUpdate
		) {
			v.ratingData = ratingData = vstar_product_rating;
		} else {
			[reqErr, ratingData] = await requests.getProductRating();
			if (reqErr === null && ratingData) v.ratingData = ratingData;
		}
	}
	if (v.ratingData && v.ratingData.rating) {
		v.ratingData.rating = parseFloat(v.ratingData.rating).toFixed(
			u.bigRatingDigits - 1
		);
	}

	//产品页星星，首页星星
	if (isProRatingOpen || isHomeRatingOpen || v.hasPyRating || v.hasEcomRt) {
		if (isProPage) {
			setTimeout(() => {
				let node = $dqs('a[href*="/products/"],.product-recommendations');
				if (node && v.isOpenRecStars) {
					getCollectionTarget();
					if (Review.collTarget.length !== 0) {
						setInsertTarget(2);
					}
					setTimeout(() => {
						setCollectionObserve();
					}, 1000);
				}
			}, 1000);
		}

		if (!shopifyProBlock && !v.hasPyRating && !v.hasEcomRt) {
			if (isHomePage) {
				if (proTarget) proInsertStar();
			} else {
				setInsertTarget(1);
			}
		}
	}

	await insertReviewControl(ratingData, windowPro);
	if (v.isProPage) {
		gsSession("set", "tt_pv_product_id", null);
		gsSession("set", "tt_cart_product_id", null);
		setRecordDataEvents();
	}
}
//Mark 评论区嵌入控制
async function insertReviewControl(ratingData, windowPro) {
	const { isMixReview, isNoNullReview, isActWdoRev, isWdoRevOpen } = v;
	const event = new CustomEvent("onTrustooReviewsEmbed", {
		detail: {
			reviews: v.reviewsDom,
		},
		bubbles: true,
		cancelable: true,
	});
	let isCustomReview = false;
	if ($dqs("#seal-review-widget,.custom-vstar-review-widget")) {
		isCustomReview = true;
	}
	if (v.isRevOpen || isCustomReview) {
		const wrapper = $dqsa("#reviews-wrapper,#noreviews-wrapper");
		if (wrapper.length !== 0) {
			wrapper.forEach(i => i.remove());
		}
		if (
			(ratingData && ratingData.rating != 0) ||
			isMixReview ||
			(isActWdoRev && isWdoRevOpen)
		) {
			v.isInsertRev = true;
			// 在确定嵌入评论区后，且脚本加载完成，嵌入评论详情弹窗
			insertReviewDetail();

			//总评不为0，有评论，进行评论区嵌入
			getReviews(windowPro);
		} else {
			if (isNoNullReview || v.userSetting.review_empty_status_display === 2) {
				Review.hasReviews = false;
				document.dispatchEvent(event);
				return;
			}
			getEmptyReview(windowPro.tarNode);
			if (typeof trustooAfterExecute !== "undefined") {
				trustooAfterExecute(v, u);
			}
		}
		setWriteReview();
	} else {
		if (v.isFirstPageRender) {
			if (typeof trustooAfterExecute !== "undefined") {
				trustooAfterExecute(v, u);
			}
		}
	}

	Review.hasReviews = Boolean(v.reviewsDom);
	document.dispatchEvent(event);
}
/**
 * 在脚本加载完成后，且确定嵌入评论区或者买家秀，并没有嵌入过评论详情弹窗，嵌入评论详情弹窗
 */
function insertReviewDetail() {
	if (
		typeof TTRevDetail !== "undefined" &&
		(v.isInsertRev || v.isInsertBS || v.isWdoRevOpen)
	) {
		TTRevDetail.insertTTReviewDetail(v);
	}
}

/**
 * @param {String} targetNode -目标元素
 * @param {String} position - 嵌入位置 - beforebegin beforeend afterbegin afterend
 */
function insertCarousel(targetNode, position = "beforebegin") {
	// const targetNode = document.querySelector(selector);
	if (!targetNode) {
		return;
	}
	if ($dqs("#trustoo-card-carousel-widget")) {
		return;
	}
	const scr = document.createElement("script");
	scr.src = `https://${v.staticBaseUrl}/static/dist/js/swiper-reviews.min.js`;
	const style = document.createElement("link");
	style.rel = "stylesheet";
	style.href = `https://${v.staticBaseUrl}/static/css/swiper-reviews.min.css`;
	document.head.appendChild(scr);
	document.head.appendChild(style);
	targetNode.insertAdjacentHTML(
		position,
		'<div id="trustoo-card-carousel-widget" data-type="script" style="max-width:1200px;margin:0 auto"></div>'
	);
}

/**
 * ## 设置收集数据事件
 */
async function setRecordDataEvents() {
	v.cartWatcher = new CartWatcher();
	v.cartWatcher.changeCount = 0;
	window.addEventListener("cart_changed", e => {
		v.cartWatcher.changeCount++;
		if (v.isRecordPV) {
			if (v.cartWatcher.changeCount > 1) {
				const cartToken = e.detail.token.split("?key=")[0];
				processRecordedData("add_to_cart", cartToken, true);
			}
		}
	});
	await v.cartWatcher.init();
	// 无评论区不进行显示记录
	if (v.reviewsDom && !v.isNullRev) {
		setReviewShowObserver();
	}
}

/**
 * ## 上传记录的数据
 * @param {String} type - 触发记录的类型 reviews_click、add_to_cart、reviews_view
 * @param {String} cartToken - 购物车token
 * @param {Boolean} isCart - 本次记录是不是购物车加购记录
 */
function processRecordedData(type, cartToken = "", isCart) {
	if (v.isProPage && v.ratingData && v.ratingData.rating == 0) {
		return;
	}
	let clientId = "";
	let uv = 0;
	if (localStorage.getItem("trustoo_uv")) {
		clientId = localStorage.getItem("trustoo_uv");
	} else {
		clientId = v.shop_id + "_" + new Date().getTime();
		localStorage.setItem("trustoo_uv", clientId);
		uv = 1;
	}
	// 存储这次pv触发的产品的id，如果购物车跳转，buy now跳转可以补发记录
	if (v.isProPage) {
		gsSession("set", "tt_pv_product_id", v.productId);
		gsSession("set", "tt_cart_product_id", v.productId);
	}

	if (!v.isRecordPV || (isCart && !v.isRecordCart)) {
		v.isRecordPV = true;
		const p = {
			client_id: clientId,
			event_category: type,
		};
		if (["reviews_click", "reviews_view"].includes(type)) {
			p.unique_visitor = uv;
		}
		if (v.productId) {
			p.product_id = v.productId;
		}
		if (cartToken) {
			p.cart_token = cartToken;
			v.isRecordCart = true;
		} else if (v.cartWatcher) {
			const token = v.cartWatcher.storedCartToken().split("?key=")[0];
			p.cart_token = token;
		}

		requests.recordInfo(p).then(() => {
			// 已记录pv，清除信息
			if (isCart) {
				gsSession("set", "tt_cart_product_id", null);
			}
		});
	}
}

// ## 设置写评论控件
async function setWriteReview() {
	if (!$dqs(".mask.review-mask")) {
		//俩个弹窗
		const data = await getWriteReviewSetting();
		const form = data.write_review_form;
		v.writeRestriction = data.leave_review_method;
		v.writeForm = form;
		if (form === 1) {
			addReviewWindow(data);
			successSend(data);
		} else if (form === 2) {
			insertSlide(data);
		}
		if (v.userSetting.is_qa_active === 1 && form !== 1) {
			addReviewWindow(data);
			successSend(data);
		}
	}
}
/**
 * ## 构造星星SVG
 * @param {Number} rating - 评分
 * @param {Number} size - 星星大小，单位px
 * @param {Number} type - 类型，1 字体文件星星图标，2 幻灯片星星图标（黑白），3 产品页、集合页评价星星图标
 * @param {Boolean} isHasHalf - 是否存在半星
 */
function getTotalStarString(rating, isHasHalf = false) {
	const { isProPage } = v;
	const icon = v.ratingIconSvg;
	let extraCls = "";
	if (!icon) {
		extraCls = " pending";
	}
	const starString = `<div class="star-item${extraCls}">${icon}</div>`;
	const nostarString = `<div class="star-item nostar${extraCls}">${icon}</div>`;

	if (!parseInt(rating)) rating = 0;
	var starTag = "";
	var starNum = Math.floor(rating);
	for (var i = 0; i < starNum; i++) {
		starTag += starString;
	}
	if (starNum < rating && isHasHalf) {
		if (isProPage) v.isProHasHalfStar = true;
		const percentage = ((rating - starNum) * 100).toFixed(2);
		starNum++;
		starTag += `<div class="star-item half-star">
		<div class="item-nostar${extraCls}">${icon}</div>
    <div class="item-star${extraCls}" style="width:${iconShowRatioAdjustment(
			percentage
		)}%">
      ${icon}
    </div>
  </div> `;
	}

	for (var i = 0; i < 5 - starNum; i++) {
		starTag += nostarString;
	}

	return starTag;
}
/**
 * ## 调整评分图标显示比例
 * @param {Number} percentage - 比例值
 */
function iconShowRatioAdjustment(percentage) {
	let temp = parseInt(percentage) / 100;
	const ratio = parseInt(percentage) / 100;
	switch (v.userSetting.rating_icon_type) {
		case 13: {
			temp = 0.28 + parseFloat((0.44 * ratio).toFixed(2));
			break;
		}
		case 15: {
			temp = 0.215 + parseFloat((0.57 * ratio).toFixed(2));
			break;
		}
	}
	temp = temp * 100;
	return temp;
}

//主页商品标题处理
function getHomeProTitle(proName) {
	return encodeURIComponent(proName);
}

async function getWriteReviewSetting() {
	let d = {
		show_email: 1,
		require_email: 1,
		show_phone: 2,
		require_phone: 2,
		show_feedback: 1,
		require_feedback: 1,
		review_button_background_color: "#000000",
		review_button_text_color: "#FFFFFF",
		thanks_button_background_color: "#000000",
		thanks_button_text_color: "#FFFFFF",
	};
	if (
		typeof vstar_write_review_settings !== "undefined" &&
		!v.isSettingUpdate
	) {
		d = vstar_write_review_settings;
	} else {
		const res = await requests.getWriteRevSetting();
		res[0] === null && (d = res[1]);
	}
	return d;
}

function starEmbeddedAdaptation(type, data) {
	for (let i = 0; i < data.length; i++) {
		let it = data[i];
		if ($dqsa(it).length !== 0) {
			let selectors = it.split(",");
			do {
				const len = selectors.length;
				let target = selectors.slice(0, Math.ceil(len / 2));
				if ($dqsa(target.join(",")).length !== 0) {
					selectors = target;
				} else {
					selectors = selectors.slice(len - parseInt(len / 2));
				}
			} while (selectors.length !== 1);
			requests.sendErrorMsg(type, selectors[0]);
			return selectors[0];
		}
	}
}

export {
	setInsertTarget,
	getTotalRating,
	getTotalStarString,
	getHomeProTitle,
	insertReviewControl,
	starEmbeddedAdaptation,
	processRecordedData,
	insertReviewDetail,
	insertCarousel,
};
